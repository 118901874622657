<template>
  <div>
    <el-dialog
      title="冲抵预付"
      :visible.sync="visible"
      append-to-body
      v-dialogDrag
      width="60%"
      :close-on-click-modal="false"
    >
      <div style="margin-bottom:8px">
        应付金额
        <el-tag type="warning">{{remainAmount|thousands}}</el-tag>元
      </div>

      <el-table
        :data="allCanCharge"
        ref="allCanCharge"
        v-loading="loading"
        border
        stripe
        max-height="345"
      >
        <el-table-column prop="billCode" label="预付单号" show-overflow-tooltip></el-table-column>
        <el-table-column label="预付日期" show-overflow-tooltip>
          <template slot-scope="scope">{{scope.row.billDate|datetime}}</template>
        </el-table-column>
        <el-table-column label="预付金额" show-overflow-tooltip>
          <template slot-scope="scope">{{scope.row.payAmount|thousands}}</template>
        </el-table-column>
        <el-table-column label="可冲抵金额" show-overflow-tooltip>
          <template slot-scope="scope">{{scope.row.remainAmount|thousands}}</template>
        </el-table-column>
        <el-table-column label="备注" prop="remark" show-overflow-tooltip></el-table-column>
        <el-table-column label="本次冲抵金额">
          <template slot-scope="scope">
            <el-popover placement="top-start" trigger="focus">
              <span style="color: teal;">{{scope.row.chargeAmount|money}}</span>
              <el-input
                slot="reference"
                type="number"
                v-model="scope.row.chargeAmount"
                @mousewheel.native.prevent
              ></el-input>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="65">
          <template slot-scope="scope">
            <el-tag
              type="primary"
              effect="dark"
              @click="()=>{scope.row.chargeAmount=Math.min(scope.row.remainAmount,remainAmount)}"
            >冲抵</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <el-button type="info" @click="save" style="margin:10px 0 0 0;">选定</el-button>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["item", "supplierId"],
  data() {
    return {
      visible: false,
      loading: false,
      allCanCharge: [],
      selected: [],
      remainAmount: 0
    };
  },
  watch: {
    item: {
      handler: function(v) {
        this.selected = v.selected;
        this.remainAmount = v.remainAmount;
        this.getAllCanCharge(v.projectId);
        this.visible = true;
      },
      deep: true
    }
  },
  methods: {
    getAllCanCharge(projectId) {
      this.loading = true;
      this.$get("Payment/GetAllCanCharge", {
        supplierId: this.supplierId,
        projectId: projectId || ""
      })
        .then(r => {
          r.forEach(item => {
            item.chargeAmount = null;
          });
          this.allCanCharge = r;
          this.setChargeAmountOrItem();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setChargeAmountOrItem() {
      this.selected.forEach(r => {
        let hasItem = false;

        this.allCanCharge.forEach(item => {
          if (r.billId == item.id) {
            item.chargeAmount = r.amount;
            hasItem = true;
          }
        });
        if (!hasItem) {
          this.$get("Payment/GetCharge", {
            isInit: r.isInit,
            billId: r.billId
          }).then(res => {
            res.chargeAmount = r.amount;
            this.allCanCharge.unshift(res);
          });
        }
      });
    },
    save() {
      let res = [];
      this.allCanCharge.forEach(item => {
        let chargeAmount = Number(item.chargeAmount);
        if (chargeAmount > 0) {
          res.push({
            isInit: item.isInit,
            billId: item.id,
            amount: chargeAmount
          });
        }
      });
      this.$emit("success", res);
      this.visible = false;
    }
  }
};
</script>

<style>
</style>