<template>
  <div>
    <el-dialog
      title="选择明细"
      :visible.sync="visible"
      append-to-body
      v-dialogDrag
      width="60%"
      :close-on-click-modal="false"
    >
      <el-table
        :data="data"
        ref="table"
        v-loading="loading"
        border
        stripe
        max-height="345"
        @expand-change="getDetail"
        @selection-change="getAmountData"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-table
              v-if="props.row.billType == 1"
              :data="props.row.detail"
              border
              stripe
              v-loading="detailLoading"
            >
              <el-table-column label="编号" prop="goodsCode" show-overflow-tooltip></el-table-column>
              <el-table-column label="名称" prop="goodsName" show-overflow-tooltip></el-table-column>
              <el-table-column label="规格" prop="goodsSpec" show-overflow-tooltip></el-table-column>
              <el-table-column label="单位" prop="goodsUnit" show-overflow-tooltip></el-table-column>
              <el-table-column label="数量" show-overflow-tooltip>
                <template slot-scope="scope">{{scope.row.quantity|thousands}}</template>
              </el-table-column>
              <el-table-column label="单价" show-overflow-tooltip>
                <template slot-scope="scope">{{scope.row.price|thousands}}</template>
              </el-table-column>
              <el-table-column label="金额" show-overflow-tooltip>
                <template slot-scope="scope">{{(scope.row.price*scope.row.quantity)|thousands(2)}}</template>
              </el-table-column>
              <el-table-column
                v-if="props.row.apportionToBalance"
                label="分摊金额"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{scope.row.apportionAmount|thousands}}</template>
              </el-table-column>
              <el-table-column label="备注" prop="remark" show-overflow-tooltip></el-table-column>
            </el-table>
            <el-table
              v-if="props.row.billType == 2"
              :data="props.row.detail"
              border
              stripe
              v-loading="detailLoading"
            >
              <el-table-column label="费用类别" prop="expenseTypeName" show-overflow-tooltip></el-table-column>
              <el-table-column label="金额" prop="amount" show-overflow-tooltip>
                <template slot-scope="scope">{{scope.row.amount|thousands}}</template>
              </el-table-column>
              <el-table-column label="备注" prop="remark" show-overflow-tooltip></el-table-column>
            </el-table>
            <el-table
              v-if="props.row.billType == 3"
              :data="props.row.detail"
              border
              stripe
              v-loading="detailLoading"
            >
              <el-table-column label="工种" prop="employeeTypeName" show-overflow-tooltip></el-table-column>
              <el-table-column label="工时费" prop="price" show-overflow-tooltip>
                <template slot-scope="scope">{{scope.row.price|thousands}}</template>
              </el-table-column>
              <el-table-column label="数量" prop="quantity" show-overflow-tooltip>
                <template slot-scope="scope">{{scope.row.quantity|thousands}}</template>
              </el-table-column>
              <el-table-column label="金额" prop="amount" show-overflow-tooltip>
                <template slot-scope="scope">{{scope.row.amount|thousands(2)}}</template>
              </el-table-column>
              <el-table-column label="备注" prop="remark" show-overflow-tooltip></el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column prop="billCode" label="单据编号" show-overflow-tooltip></el-table-column>
        <el-table-column label="单据日期" show-overflow-tooltip>
          <template slot-scope="scope">{{scope.row.billDate|datetime}}</template>
        </el-table-column>
        <el-table-column prop="billTypeName" label="单据类型" show-overflow-tooltip></el-table-column>
        <el-table-column prop="locationName" label="项目/仓库" show-overflow-tooltip></el-table-column>
        <el-table-column label="单据金额" show-overflow-tooltip>
          <template slot-scope="scope">{{scope.row.amount|thousands}}</template>
        </el-table-column>
        <el-table-column label="结算金额" show-overflow-tooltip>
          <template slot-scope="scope">{{scope.row.balanceAmount|thousands}}</template>
        </el-table-column>
        <el-table-column label="应付金额" show-overflow-tooltip>
          <template slot-scope="scope">{{scope.row.remainAmount|thousands}}</template>
        </el-table-column>
        <el-table-column label="备注" prop="remark" show-overflow-tooltip></el-table-column>
      </el-table>
      <el-button type="info" @click="save" style="margin:10px 0 0 0;">选定</el-button>
      <span style="margin-left:10px">
        应付合计
        <el-tag type="warning">{{totalRemainAmount|thousands}}</el-tag>元
      </span>
    </el-dialog>
  </div>
</template>

<script>
import setName from "@/common/setName";
export default {
  props: ["item", "supplierId"],
  data() {
    return {
      visible: false,
      loading: false,
      detailLoading: false,
      data: [],
      totalRemainAmount: 0
    };
  },
  watch: {
    item: {
      handler: function() {
        this.getData();
        this.visible = true;
      },
      deep: true
    }
  },
  methods: {
    getData() {
      this.data = [];
      this.loading = true;
      this.$get("Payment/GetPaymentItem", {
        supplierId: this.supplierId
      })
        .then(r => {
          r.forEach(item => {
            item.detail = [];
            item.remainAmount = this.amountFormat(
              item.amount - item.balanceAmount
            );
            //0期初1采购2项目费用3人工费用
            switch (item.billType) {
              case 0:
                item.billTypeName = "期初";
                item.locationName = "-";
                break;
              case 1:
                if (item.purchaseType == 1) {
                  item.billTypeName = "项目采购";
                  item.projectId = item.locationId;
                  setName("Project", item, "locationId", "locationName");
                }
                if (item.purchaseType == 2) {
                  item.billTypeName = "仓库采购";
                  item.projectId = null;
                  setName("Store", item, "locationId", "locationName");
                }
                break;
              case 2:
                item.billTypeName = "项目费用";
                setName("Project", item, "projectId", "locationName");
                break;
              case 3:
                item.billTypeName = "人工费用";
                setName("Project", item, "projectId", "locationName");
                break;
            }
          });
          this.data = r;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getDetail(row) {
      //1采购2项目费用3人工费用
      if (row.detail.length == 0 && row.billType != 0) {
        this.detailLoading = true;
        switch (row.billType) {
          case 1:
            this.$get("Payment/GetPurchaseDetail", {
              purchaseId: row.id
            })
              .then(r => {
                row.detail = r;
              })
              .finally(() => {
                this.detailLoading = false;
              });
            break;
          case 2:
            this.$get("Payment/GetExpenseDetail", {
              expenseId: row.id
            })
              .then(r => {
                r.forEach(item => {
                  setName(
                    "ExpenseType",
                    item,
                    "expenseTypeId",
                    "expenseTypeName"
                  );
                });
                row.detail = r;
              })
              .finally(() => {
                this.detailLoading = false;
              });
            break;
          case 3:
            this.$get("Payment/GetLaborDetail", {
              laborId: row.id
            })
              .then(r => {
                r.forEach(item => {
                  item.amount = this.amountFormat(item.price * item.quantity);
                  setName(
                    "EmployeeType",
                    item,
                    "employeeTypeId",
                    "employeeTypeName"
                  );
                });
                row.detail = r;
              })
              .finally(() => {
                this.detailLoading = false;
              });
            break;
          default:
            this.detailLoading = false;
        }
      }
    },
    getAmountData(selection) {
      let total = 0;
      selection.forEach(item => {
        total += item.remainAmount;
      });
      this.totalRemainAmount = this.amountFormat(total);
    },
    save() {
      let selection = this.$refs.table.selection;
      if (selection.length == 0) {
        this.$message.error("请选择明细");
        return;
      }
      let res = [];
      selection.forEach(item => {
        res.push({
          billId: item.id,
          projectId: item.projectId,
          billType: item.billType,
          billCode: item.billCode,
          billDate: item.billDate,
          locationName: item.locationName,
          billAmount: item.amount,
          remainAmount: item.remainAmount,
          payAmount: null,
          balanceAmount: null,
          remark: "",
          chargeAmount: 0
        });
      });
      this.$emit("success", res);
      this.visible = false;
    }
  }
};
</script>

<style>
</style>