const mixin = {
    //billType:1项目2合同3采购
    methods: {
        getBillCode(billType) {
            if (!this.form.billDate || !billType) {
                this.form.billCode = "";
            }
            this.$get('Sys/GetBillCode', {
                billType: billType,
                billDate: this.form.billDate
            }).then(r => {
                this.form.billCode = r;
            })
        }
    }
}



export default mixin;