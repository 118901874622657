<template>
  <div>
    <div class="bill-title">付款单</div>
    <el-form
      :model="form"
      status-icon
      :rules="rules"
      ref="form"
      label-width="80px"
    >
      <el-row>
        <el-col :span="6">
          <el-form-item label="单据编号" prop="billCode">
            <el-input v-model="form.billCode" placeholder="付款编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="单据日期" prop="billDate">
            <el-date-picker
              style="width: 150px"
              @change="getBillCode(6)"
              v-model="form.billDate"
              placeholder="单据日期"
              :clearable="false"
              :editable="false"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="单据类型" prop="billType">
            <el-radio-group
              :disabled="form.id != undefined"
              v-model.number="form.billType"
            >
              <el-radio :label="1" border>预付</el-radio>
              <el-radio :label="2" border>应付</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="供应商" prop="supplierId">
            <base-select
              @input="supplierChange"
              info="Supplier"
              v-model="form.supplierId"
            ></base-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="应付/预付">
            <el-tag>{{ balance | thousands }}/{{ repay | thousands }}</el-tag>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="银行账号">
            <el-input
              v-model="form.bankNo"
              placeholder="银行账号"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item
            label="预付金额"
            prop="payAmount"
            v-if="form.billType == 1"
          >
            <el-popover ref="payAmount" placement="top-start" trigger="focus">
              <span style="color: teal">{{ form.payAmount | money }}</span>
            </el-popover>
            <el-input
              v-popover:payAmount
              type="number"
              v-model="form.payAmount"
              placeholder="金额"
              clearable
              @mousewheel.native.prevent
            >
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="预付项目" v-if="form.billType == 1">
            <project-select
              v-model="form.projectId"
              :state="[1, 2]"
              :addId="addProjectId"
            ></project-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="摘要">
        <el-input v-model="form.remark" placeholder="摘要" clearable></el-input>
      </el-form-item>
      <el-form-item label="单据附件">
        <BillAttach :attachId="form.attachId"></BillAttach>
      </el-form-item>
      <div v-if="form.billType == 2">
        <el-form-item>
          <template slot="label">
            <strong>付款明细</strong>
          </template>
          <el-button-group>
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-plus"
              @click="selectDetail"
            ></el-button>
            <el-button size="mini" type="success" @click="balanceAll"
              >自动结算</el-button
            >
          </el-button-group>
        </el-form-item>
        <div style="margin: -8px 0px 8px 80px">
          <el-table :data="detailList" stripe border @row-dblclick="goBill">
            <el-table-column
              type="index"
              width="50"
              label="序号"
            ></el-table-column>
            <el-table-column
              label="单据编号"
              prop="billCode"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column label="单据日期" show-overflow-tooltip>
              <template slot-scope="scope">{{
                scope.row.billDate | datetime
              }}</template>
            </el-table-column>
            <el-table-column
              label="项目/仓库"
              prop="locationName"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column label="单据金额" show-overflow-tooltip>
              <template slot-scope="scope">{{
                scope.row.billAmount | thousands
              }}</template>
            </el-table-column>
            <el-table-column label="应付金额" show-overflow-tooltip>
              <template slot-scope="scope">{{
                scope.row.remainAmount | thousands
              }}</template>
            </el-table-column>
            <el-table-column label="冲抵预付" show-overflow-tooltip>
              <template slot-scope="scope">{{
                scope.row.chargeAmount | thousands
              }}</template>
            </el-table-column>
            <el-table-column label="付款金额">
              <template slot-scope="scope">
                <el-popover placement="top-start" trigger="focus">
                  <span style="color: teal">{{
                    scope.row.payAmount | money
                  }}</span>
                  <el-input
                    slot="reference"
                    type="number"
                    v-model="scope.row.payAmount"
                    @mousewheel.native.prevent
                  ></el-input>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column label="结算金额">
              <template slot-scope="scope">
                <el-popover placement="top-start" trigger="focus">
                  <span style="color: teal">{{
                    scope.row.balanceAmount | money
                  }}</span>
                  <el-input
                    slot="reference"
                    type="number"
                    v-model="scope.row.balanceAmount"
                    @mousewheel.native.prevent
                  ></el-input>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column label="备注">
              <template slot-scope="scope">
                <el-input v-model="scope.row.remark" clearable></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template slot-scope="scope">
                <el-tag
                  type="success"
                  effect="dark"
                  @click="selectCharge(scope.row)"
                  >预付</el-tag
                >
                <el-tag
                  style="margin-left: 6px"
                  type="danger"
                  effect="dark"
                  @click="removeDetail(scope.row)"
                  >移除</el-tag
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <el-form-item>
        <span v-if="canntEditMsg" style="color: red"
          >{{ canntEditMsg }} 单据不可编辑</span
        >
        <el-button-group v-else>
          <el-button type="info" @click="save(false)">保存关闭</el-button>
          <el-button type="success" @click="save(true)">保存新增</el-button>
          <el-button type="warning" @click="cancel">取消关闭</el-button>
          <el-button type="danger" @click="deleteBill" v-if="form.id&&hasPermission('DeleteBill')"
            >删除单据</el-button
          >
        </el-button-group>

        <span v-if="form.billType == 2">
          <span style="margin-left: 10px">
            冲抵预付
            <el-tag type="warning">{{ totalChargeAmount | thousands }}</el-tag
            >元
          </span>
          <span style="margin-left: 10px">
            付款合计
            <el-tag type="warning">{{ totalPayAmount | thousands }}</el-tag
            >元
          </span>
          <span style="margin-left: 10px">
            结算合计
            <el-tag type="warning">{{ totalBalanceAmount | thousands }}</el-tag
            >元
          </span>
        </span>
      </el-form-item>
      <el-form-item>
        <operator-box :item="form"></operator-box>
      </el-form-item>
    </el-form>
    <div>
      <payment-detail
        :item="detail"
        :supplierId="form.supplierId"
        @success="addDetail"
      ></payment-detail>
      <PaymentCharge
        :item="charge"
        :supplierId="form.supplierId"
        @success="addCharge"
      ></PaymentCharge>
    </div>
  </div>
</template>

<script>
import billcode from "@/common/billcodeMixins.js";
import BaseSelect from "@/components/BaseSelect.vue";
import ProjectSelect from "@/components/ProjectSelect.vue";
import PaymentDetail from "@/views/payment/Detail.vue";
import PaymentCharge from "@/views/payment/Charge.vue";
import OperatorBox from "@/components/OperatorBox";
import guid from "@/common/guid.js";
import BillAttach from "@/components/BillAttach";
export default {
  mixins: [billcode],
  components: {
    BaseSelect,
    PaymentDetail,
    PaymentCharge,
    ProjectSelect,
    OperatorBox,
    BillAttach,
  },
  data() {
    return {
      routeTab: "付款单",
      form: {},
      rules: {
        billCode: [this.$g.required],
        billDate: [this.$g.required],
        billType: [this.$g.required],
        supplierId: [this.$g.required],
        payAmount: [this.$g.required, this.$g.notZero],
      },

      detailList: [],
      chargeList: [],
      detail: {},
      charge: {},
      //记录当前选择的应付明细的单据Id
      billId: null,

      addProjectId: null, //不可用项目Id(编辑时要显示)
      stopList: [], //停用基础信息
      canntEditMsg: "", //不可编辑的提示信息

      balance: 0,
      repay: 0,
    };
  },
  computed: {
    totalChargeAmount() {
      let res = 0;
      this.detailList.forEach((item) => {
        res += Number(item.chargeAmount);
      });
      return res;
    },
    totalPayAmount() {
      let res = 0;
      this.detailList.forEach((item) => {
        res += Number(item.payAmount);
      });
      return res;
    },
    totalBalanceAmount() {
      let res = 0;
      this.detailList.forEach((item) => {
        res += Number(item.balanceAmount);
      });
      return res;
    },
  },
  mounted() {
    this.init(this.$route.query.id);
  },
  beforeDestroy() {
    this.stopList.forEach((item) => {
      this.$store.commit("removeBaseInfo", {
        info: item.info,
        id: item.baseInfo.id,
      });
    });
  },
  methods: {
    init(id) {
      this.addProjectId = null;
      this.stopList = [];
      this.canntEditMsg = "";
      if (id) {
        this.editInit(id);
      } else {
        this.addInit();
      }
    },
    //新增
    addInit() {
      this.balance = 0;
      this.repay = 0;
      this.form = {
        billCode: "",
        billDate: new Date(),
        billType: 2,
        payAmount: null,
        projectId: "",
        supplierId: "",
        remark: "",
        bankNo: "",
        attachId: guid(),
      };
      this.detailList = [];
      this.chargeList = [];
      this.getBillCode(6);
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    //编辑
    editInit(id) {
      this.$get("Payment/Detail", {
        id,
      }).then((r) => {
        this.routeTab = r.bill.billCode;
        this.form = { ...r.bill };
        this.detailList = [...r.detail];
        this.chargeList = [...r.charge];
        this.balance = r.balance;
        this.repay = r.repay;
        this.stopList = r.stopList;
        this.addProjectId = r.addProjectId;
        this.canntEditMsg = r.canntEditMsg;
        this.stopList.forEach((item) => {
          this.$store.commit("addBaseInfo", {
            info: item.info,
            item: item.baseInfo,
          });
        });
      });
    },
    supplierChange(supplierId) {
      this.detailList = [];
      if (!supplierId) {
        this.balance = 0;
        this.repay = 0;
        this.form.bankNo = "";
        return;
      }
      this.$get("Supplier/GetSupplierInfo", {
        supplierId,
      }).then((r) => {
        this.balance = r.balance;
        this.repay = r.repay;
        this.form.bankNo = r.bankNo;
      });
    },
    selectDetail() {
      if (!this.form.supplierId) {
        this.$message.error("请选择供应商");
        return;
      }
      this.detail = {};
    },

    addDetail(array) {
      array.forEach((item) => {
        let index = this.detailList.findIndex((r) => r.billId == item.billId);
        if (index == -1) {
          this.detailList.push(item);
        }
      });
    },
    removeDetail(row) {
      //应收移除
      let index = -1;
      this.detailList.forEach((item, i) => {
        if (item.billId == row.billId) {
          index = i;
        }
      });
      if (index != -1) {
        this.detailList.splice(index, 1);
      }
      //预付移除
      let temp = [];
      this.chargeList.forEach((item) => {
        if (item.detailBillId != row.billId) {
          temp.push(item);
        }
      });
      this.chargeList = temp;
    },
    //选择预付
    selectCharge(row) {
      this.billId = row.billId;
      let selected = [];
      this.chargeList.forEach((item) => {
        if (item.detailBillId == this.billId) {
          selected.push(item);
        }
      });
      this.charge = {
        selected: selected,
        projectId: row.projectId,
        remainAmount: row.remainAmount,
      };
    },
    addCharge(array) {
      //删除原有的
      let temp = [];
      this.chargeList.forEach((item) => {
        if (item.detailBillId != this.billId) {
          temp.push(item);
        }
      });
      this.chargeList = temp;
      //添加到list，计算chargeAmount
      let chargeAmount = 0;
      array.forEach((item) => {
        item.detailBillId = this.billId;
        this.chargeList.push(item);
        chargeAmount += Number(item.amount);
      });
      //加入到付款明细
      this.detailList.forEach((item) => {
        if (item.billId == this.billId) {
          item.chargeAmount = chargeAmount;
        }
      });
    },
    //自动结算
    balanceAll() {
      this.detailList.forEach((item) => {
        item.payAmount = this.amountFormat(
          item.remainAmount - item.chargeAmount
        );
        item.balanceAmount = this.amountFormat(item.remainAmount);
      });
    },
    //保存
    save(isContinue) {
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return;
        }
        if (this.form.billType == 2 && this.detailList.length == 0) {
          this.$message.error("请添加付款明细");
          return;
        }
        let bill = { ...this.form };
        let detail = [];
        let charge = [...this.chargeList];
        let error = "";
        this.detailList.forEach((item, i) => {
          item.payAmount = Number(item.payAmount);
          item.balanceAmount = Number(item.balanceAmount);
          item.chargeAmount = Number(item.chargeAmount);
          //有采购退货
          if (item.payAmount == 0 && item.balanceAmount == 0) {
            error =
              "第【" + (i + 1) + "】条明细错误，付款和结算金额不能同时为0";
          }
          detail.push({
            billType: item.billType,
            billId: item.billId,
            remainAmount: item.remainAmount,
            payAmount: item.payAmount,
            balanceAmount: item.balanceAmount,
            remark: item.remark,
            chargeAmount: item.chargeAmount,
          });
        });
        if (error) {
          this.$message.error(error);
          return;
        }
        let loading = this.$loading({
          lock: true,
          text: "保存中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        let url = "";
        if (this.form.id) {
          url = "Payment/Edit";
        } else {
          url = "Payment/Add";
        }
        this.$post(url, {
          bill,
          detail,
          charge,
        })
          .then((r) => {
            this.$message.success("操作成功！");
            if (isContinue) {
              this.$tabs.close({
                to: {
                  name: "PaymentItem",
                  query: {},
                },
                refresh: true,
              });
            } else {
              this.$tabs.close();
            }
          })
          .finally(() => {
            loading.close();
          });
      });
    },
    //删除单据
    deleteBill() {
      this.$confirm("将删除此付款单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let loading = this.$loading({
            lock: true,
            text: "删除中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          this.$post("Payment/Delete?id=" + this.form.id)
            .then((r) => {
              this.$message.success("操作成功！");
              this.$g.deleteAttach(this.form.attachId);
              this.$tabs.close();
            })
            .finally(() => {
              loading.close();
            });
        })
        .catch(() => {});
    },
    cancel() {
      if (!this.form.id) {
        this.$g.deleteAttach(this.form.attachId);
      }
      this.$tabs.close();
    },
    goBill(row) {
      //编辑状态下进入相应单据
      if (this.form.id) {
        switch (row.billType) {
          case 1:
            if (this.hasPermission("Purchase")) {
              this.$router.push({
                name: "PurchaseItem",
                query: { id: row.billId },
              });
            }
            break;
          case 2:
            if (this.hasPermission("Expense")) {
              this.$router.push({
                name: "ExpenseItem",
                query: { id: row.billId },
              });
            }
            break;
          case 3:
            if (this.hasPermission("Labor")) {
              this.$router.push({
                name: "LaborItem",
                query: { id: row.billId },
              });
            }
            break;
          default:
            break;
        }
      }
    },
  },
};
</script>
